import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, InputNumber, Space } from "antd";
import axios from "axios";
import config from "../config";
import { useSelector } from "react-redux";
const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 12 },
};

const AddQuestions = () => {
  const [form] = Form.useForm();
  const [cat, setCat] = useState();

  const [categories, setCategories] = useState([]);
  const [pastPaperTitle, setPastPaperTitle] = useState();
  const [titleId, setTitleId] = useState("");
  const [pastPaperYear, setPastPaperYear] = useState();
  const {token} = useSelector((state) => state.authReducer);
  const[dbCategory,setDbCategory]=useState()
  const [select3,setSelect3]=useState("")
  useEffect(() => {
    axios.get(`${config.url}category/menu`).then((res) => {
      setCategories(res.data);
    });
  }, []);
  const createQuestion = (value) => {
    delete value["parentCategory"];
    delete value["PastPaperTitle"];
    value.category=dbCategory
    axios
      .post(`${config.url}questions/`, value, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        //form.resetFields();
        form.setFieldsValue({
          question: "",
          answer: "",
        });
      });
  };
  const handleSelect = (value) => {
    setCat(value);
    setDbCategory(value)
    setPastPaperTitle(categories.filter((item) => item._id === value));
    setPastPaperYear([])
    form.setFieldsValue({
      PastPaperTitle:"",
      category:""

    })
    setTitleId('')
  };
  const handleTitleSelect = (value) => {
    setTitleId(value);
    setSelect3('')
    setPastPaperYear(
      pastPaperTitle[0]?.subMenu.filter((item) => item._id === value)
    );
  };
  const handlePastSelect =(value)=>{
    setSelect3(value)
    setDbCategory(value)
  }
  return (
    <>
      <Form
        {...layout}
        name="basic"
        onFinish={(value) => createQuestion(value)}
        form={form}
      >
        <Form.Item
          label="Category"
          name="parentCategory"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select onChange={handleSelect}>
            {categories.map((category, index) => {
              return (
                <Option value={category._id} key={index.toString()}>
                  {category.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {cat === "6173d59631c271202cfdcd7c" && (
          <>
            <Form.Item
              label="PastPaper Title"
              name="PastPaperTitle"
              rules={[
                { required: true, message: "Please select a PastPaper title" },
              ]}
            >
              <Select value={titleId} onChange={handleTitleSelect}>
                {pastPaperTitle &&
                  pastPaperTitle[0].subMenu &&
                  pastPaperTitle[0].subMenu.map((category, index) => {
                    return (
                      <Option value={category._id} key={index.toString()}>
                        {category.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Past Paper Year"
              name="category"
              rules={[
                { required: true, message: "Please select a Past Paper Year" },
              ]}
            >
              <Select value={select3} onChange={handlePastSelect}>
                {pastPaperYear &&
                  pastPaperYear[0]?.subMenu.map((category, index) => {
                    return (
                      <Option value={category._id} key={index.toString()}>
                        {category.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </>
        )}
        <Form.Item
          label="Question"
          name="question"
          rules={[{ required: true, message: "Please enter question text" }]}
        >
          <TextArea autoSize />
        </Form.Item>
        <Form.Item
          label="Answer"
          name="answer"
          rules={[{ required: true, message: "Please provide answer" }]}
        >
          <TextArea autoSize />
        </Form.Item>
        {cat === "6173d59631c271202cfdcd7c" ? (
          <>
            <Form.Item
              label="Subject"
              name="subject"
              rules={[{ required: true, message: "Please enter subject name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Year"
              name="year"
              rules={[
                {
                  required: true,
                  message: "Please enter year of examinations",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </>
        ) : null}

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" shape="round" htmlType="submit">
              Submit
            </Button>

            <Button
              type="primary"
              shape="round"
              danger
              onClick={() => form.resetFields()}
            >
              Clear
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddQuestions;

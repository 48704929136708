import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, InputNumber, Space } from "antd";
import axios from "axios";
import config from "../config";
import { useSelector } from "react-redux";
import EditorConvertToHTML from "./Editor";
const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 12 },
};

const AddEssay = () => {
  const [form] = Form.useForm();
  const [cat, setCat] = useState();
  const [categories, setCategories] = useState();
  const [essayTitle, setEssayTitle] = useState();

  const {token} = useSelector((state) => state.authReducer);
  const [html, sethtml] = useState("");
  useEffect(() => {
    axios.get(`${config.url}category/menu`).then((res) => {
      const filterData = res.data.filter(
        (item) => item._id === "6173d5af31c271202cfdcd7d"
      );
      setCategories(filterData[0]);
    });
  }, []);
  const createQuestion = (value) => {
    delete value["parentCategory"];
    value.essay = html;
    axios
      .post(`${config.url}questions/`, value, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        form.resetFields();
        sethtml("");
      });
  };

  const getHtml = (html) => {
    sethtml(html);
  };
  const handleFirstSelect = (value) => {
    const dataFilter = categories?.subMenu.filter((item) => item._id === value);
    setEssayTitle(dataFilter[0]);
  };
  return (
    <>
      <Form
        {...layout}
        name="essay"
        onFinish={(value) => createQuestion(value)}
        form={form}
        key="1"
        className="addEssay"
      >
        <Form.Item
          label="Category"
          name="parentCategory"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select onChange={handleFirstSelect}>
            {categories &&
              categories.subMenu.map((category, index) => (
                <Option value={category._id} key={index.toString()}>
                  {category.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Sub Category"
          name="category"
          rules={[{ required: true, message: "Please select a sub category" }]}
        >
          <Select>
            {essayTitle &&
              essayTitle.subMenu.map((category, index) => (
                <Option value={category._id} key={index.toString()}>
                  {category.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label="Essay" className="essay" name="essay">
          <EditorConvertToHTML
            getHtml={getHtml}
            html={html}
          ></EditorConvertToHTML>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" shape="round" htmlType="submit">
              Submit
            </Button>

            <Button
              type="primary"
              shape="round"
              danger
              onClick={() => form.resetFields()}
            >
              Clear
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddEssay;

import React, { useState, useEffect } from "react";
import { Button, Input, Form } from "antd";
import axios from "axios";
import config from "../../config";
import { useSelector, useDispatch } from "react-redux";
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 12 },
};

function AddCategories({ setCategories }) {
  const {token} = useSelector((state) =>state.authReducer);
  const [form] = Form.useForm();
  const createCategory = (values) => {
    axios
      .post(`${config.url}category/`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        form.resetFields();
        axios
          .get(`${config.url}category/`)
          .then((response) => {
            setCategories(response.data);
          })
          .catch((err) => setCategories([]));
      });
  };
  return (
    <>
      <Form {...layout} name="basic" form={form} onFinish={createCategory}>
        <Form.Item
          label="Category"
          name="name"
          rules={[{ required: true, message: "Please input Category name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" shape="round" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
export default AddCategories;

import React, { useEffect, useState } from "react";
import {
  Comment,
  Avatar,
  Empty,
  Tooltip,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Space,
  InputNumber,
  Spin,
  Radio,
} from "antd";
import config from "../config";
import axios from "axios";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  UngroupOutlined,
  EditOutlined,
  DeleteFilled,
  BookOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import "./styles/question.css";
import Essay from "./Essay";
const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 12 },
};
const { Search } = Input;
const QuestionList = (props) => {
  const [form] = Form.useForm();
  const [cat, setCat] = useState();
  const [editId, setEditId] = useState("");
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [modeShow, setModeShow] = useState(false);
  const {user} = useSelector((state) =>state.authReducer);
  const {token} = useSelector((state) => state.authReducer);
  const [page, setPge] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    setQuestions([]);
    setHasMore(true);
    setPge(1);
    return () => {};
  }, [props.category]);
  useEffect(() => {
    if (props.category._id === "all") {
      axios.get(`${config.url}questions/?page=${page}`).then((ques) => {
        setQuestions(ques.data);
      });
    } else {
      if (props.category._id) {
        debugger;
        axios
          .get(
            `${config.url}questions/category?id=${props.category._id}&page=${page}`
          )
          .then((ques) => {
            setQuestions(ques.data);
          });
      }
      // } else {
      //   axios
      //     .get(
      //       `${config.url}questions/question-title?title=${props.category.title}&page=${page}`
      //     )
      //     .then((ques) => {
      //       setQuestions(ques.data);
      //     });
      // }
    }
  }, [props.category]);
  useEffect(() => {
    axios.get(`${config.url}category/`).then((res) => {
      setCategories(res.data);
    });
  }, []);
  const handleDelete = (id) => {
    console.log(id);
    axios
      .delete(`${config.url}questions/question_id/${id}`)
      .then((res) => {
        setQuestions(questions.filter((item) => item._id !== id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleEdit = (question) => {
    setModeShow(true);
    setCat(question.category._id);
    setEditId(question._id);
    form.setFieldsValue({
      category: question.category._id,
      question: question.question,
      answer: question.answer,
      subject: question.subject && question.subject,
      year: question.year && question.year,
      title: question.title && question.title,
    });
  };
  const createQuestion = (value) => {
    axios
      .put(`${config.url}questions/updateQuestion/${editId}`, value, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        questions.map((item) => {
          if (item._id === res.data._id) {
            item.question = res.data.question;
            item.answer = res.data.answer;
            item.subject && (item.subject = res.data.subject);
            item.year && (item.year = res.data.year);
            item.title && (item.title = res.data.title);
          }
        });
        setQuestions(questions);
        setModeShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchMoreData = () => {
    setPge(page + 1);
    if (props.category._id === "all") {
      axios.get(`${config.url}questions/?page=${page + 1}`).then((ques) => {
        if (!ques.data.length) {
          setHasMore(false);
          return;
        }
        setQuestions(questions.concat(ques.data));
      });
    } else {
      if (props.category._id) {
        axios
          .get(
            `${config.url}questions/category?id=${props.category._id}&page=${
              page + 1
            }`
          )
          .then((ques) => {
            if (!ques.data.length) {
              setHasMore(false);
              return;
            }
            setQuestions(questions.concat(ques.data));
          });
      }
      // else {
      //   axios
      //     .get(
      //       `${config.url}questions/question-title?title=${
      //         props.category.title
      //       }&page=${page + 1}`
      //     )
      //     .then((ques) => {
      //       console.log("length:", ques.length);
      //       if (!ques.data.length) {
      //         debugger;
      //         setHasMore(false);
      //         return;
      //       }
      //       setQuestions(questions.concat(ques.data));
      //     });
      // }
    }
  };
  return (
    <>
      {questions.length > 1 && (
        <>
          {/* <div className="search">
            <Search placeholder="search" enterButton />
          </div> */}
          <InfiniteScroll
            dataLength={questions.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              questions.length !== 0 && (
                <h4 style={{ textAlign: "center" }}>Loading...</h4>
              )
            }
            scrollableTarget="content-questions"
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Hi! You have seen it all</b>
              </p>
            }
          >
            {questions.map((question, index) => (
              <>
                <div key={index}>
                  <Comment
                    key={index}
                    author={
                      <div>
                        <b>{question.question}</b>{" "}
                        {user.role === "admin" ? (
                          <>
                            {" "}
                            <Button
                              type="primary"
                              size="small"
                              onClick={() => handleEdit(question)}
                            >
                              <EditOutlined />
                            </Button>{" "}
                            <Button
                              onClick={() => handleDelete(question._id)}
                              type="primary"
                              danger
                              size="small"
                            >
                              <DeleteFilled />
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    }
                    avatar={<Avatar>#{index + 1}</Avatar>}
                    content={<p>{question.answer}</p>}
                    actions={[
                      <Tooltip key="comment-basic-like" title="Category">
                        <span>
                          <span>
                            {<UngroupOutlined />} {question.category.name}
                          </span>
                        </span>
                      </Tooltip>,
                      <Tooltip key="subject" title="Subject">
                        {question.subject ? (
                          <span>
                            <span>
                              {<BookOutlined />} {question.subject}
                            </span>
                          </span>
                        ) : null}
                      </Tooltip>,
                      <Tooltip key="year" title="Year">
                        {question.year ? (
                          <span>
                            <span>
                              {<CalendarOutlined />} {question.year}
                            </span>
                          </span>
                        ) : null}
                      </Tooltip>,
                    ]}
                  />
                </div>
              </>
            ))}
          </InfiniteScroll>
        </>
      )}
      {questions.length === 1 && <Essay essay={questions[0].essay} />}
      {questions.length === 0 ? (
        <Spin
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "13rem",
          }}
          size="large"
          spinning={true}
        />
      ) : null}
      <Modal
        title="Edit Question"
        visible={modeShow}
        centered
        maskClosable={false}
        onCancel={() => setModeShow(false)}
        footer={null}
      >
        <>
          <Form
            {...layout}
            name="basic"
            onFinish={(value) => createQuestion(value)}
            form={form}
          >
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true, message: "Please select a category" }]}
            >
              <Select disabled={true} onChange={(e) => setCat(e)}>
                {categories.map((category, index) => {
                  return (
                    <Option value={category._id} key={index.toString()}>
                      {category.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="Question"
              name="question"
              rules={[
                { required: true, message: "Please enter question text" },
              ]}
            >
              <TextArea autoSize />
            </Form.Item>
            <Form.Item
              label="Answer"
              name="answer"
              rules={[{ required: true, message: "Please provide answer" }]}
            >
              <TextArea autoSize />
            </Form.Item>
            {cat === "5fff0b1d9e31a5e9f23ab8df" ? (
              <>
                <Form.Item
                  label="Subject"
                  name="subject"
                  rules={[
                    { required: true, message: "Please enter subject name" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    { required: true, message: "Please enter title text" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Year"
                  name="year"
                  rules={[
                    {
                      required: true,
                      message: "Please enter year of examinations",
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </>
            ) : null}

            <Form.Item {...tailLayout}>
              <Space>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </>
      </Modal>
    </>
  );
};

export default QuestionList;

import React, { useEffect, useState } from "react";
import "./layout.css";
import Navbar from "../common/navbar/navbar";
import Home from "../home/home";
import { Affix, Layout as AntLayout } from "antd";
import { Route, Switch, Redirect } from "react-router";
import gkopklogo from "../../images/Logo.png";
import Footer from "../common/footer/footer";
import About from "../about/about";
import MostRepeated from "../mostRepeated/mostRepeated";
import Essay from "../essay/Essay";
import PrivacyTerms from "../privacy/PrivacyTerms";
import ContactUs from "../contactUs/ContactUs";
import AdLogin from "../../containers/AdLogin";
import Dashboard from "../../containers/dashboard";
import My404Component from "../My404Component";
import { useSelector } from "react-redux";
function Layout() {
  const { Content } = AntLayout;
  const [isHamburger, setIsHamburger] = useState(false);
  const { token } = useSelector((state) => state.authReducer);
  useEffect(() => {
    setIsHamburger(window.innerWidth <= 970 ? true : false);
  }, []);

  window.addEventListener("resize", () => {
    setIsHamburger(window.innerWidth <= 970 ? true : false);
  });

  return (
    <AntLayout id="main-layout">
      {!token && (
        <Affix>
          <Navbar
            img={gkopklogo}
            className={"black-navbar"}
            isHamburger={isHamburger}
          />
        </Affix>
      )}
      <Content>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/most-repeated" component={MostRepeated} />
          <Route path="/ongoing-event" component={MostRepeated} />
          <Route path={`/past-paper/:id`} component={MostRepeated} />
          <Route path="/categories/:id" component={MostRepeated} />
          <Route path="/essay" component={Essay} />
          <Route path="/privacy" component={PrivacyTerms} />
          <Route path="/contacts" component={ContactUs} />

          <Route exact path="/administrator">
            {token ? <Redirect to="/dashboard" /> : <AdLogin />}
          </Route>
          <Route path="/dashboard">
            {token ? <Dashboard /> : <Redirect to="/administrator" />}
          </Route>
          <Route path="*" exact={true}>
            <My404Component />
          </Route>
        </Switch>
      </Content>
      {!token && <Footer />}
    </AntLayout>
  );
}
export default Layout;

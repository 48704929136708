import React, { Fragment, useEffect } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Link,
  useHistory,
} from "react-router-dom";
import Home from "../components/home";
import { Layout, Menu } from "antd";
import Questions from "../components/questions";
import Categories from "../components/categories";
import { useDispatch } from "react-redux";
import "./styles/dashboard.css";
const { Header, Content, Footer } = Layout;

export default function Dashboard() {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {}, []);
  const logout = () => {
    dispatch({ type: "logout" });
    history.push("/administrator");
  };
  return (
    <Layout className="layout">
      <Header>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>
            <div className="logo" />
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
              <Menu.Item key="1">
                <Link to="/dashboard/">Home</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/dashboard/questions">Questions</Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to="/dashboard/categories">Categories</Link>
              </Menu.Item>
            </Menu>
          </div>
          <div>
            <Menu theme="dark" mode="horizontal">
              <Menu.Item key="1">
                <span onClick={() => logout()}>Logout</span>
              </Menu.Item>
            </Menu>
          </div>
        </div>
      </Header>
      <Content style={{ padding: "0 50px", height: "81vh" }}>
        <Fragment>
          <Switch>
            <Route exact path={`${url}/`}>
              <Home />
            </Route>
            <Route path={`${url}/questions`}>
              <Questions />
            </Route>
            <Route path={`${url}/categories`}>
              <Categories />
            </Route>
          </Switch>
        </Fragment>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        GKOPK ©2021 Created by ESSEMMKAYY
      </Footer>
    </Layout>
  );
}

const initialState = {
  token: "",
  user: {},
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case "login":
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
      };
    case "logout":
      return {
        ...state,
        token: "",
        user: {},
      };
    default:
      return state;
  }
};

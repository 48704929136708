import React, { useEffect, useState } from "react"
import { Form, Input, Button } from 'antd';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

import config from "../config"
import "./styles/login.css"


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const AdLogin = () => {
    const {token} = useSelector(state => state.authReducer)
    const dispatch = useDispatch();
    const history = useHistory();
    const [loginError, setError] = useState(false);
    useEffect(() => {
        if (token) {
            history.push("/dashboard");
        }
    })

    const onFinish = (values) => {
        login(values.email, values.password);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const login = async (email, password) => {
        axios.post(`${config.url}user/admin/login`, { email, password }).then(response => {
            dispatch({ type: "login", payload: response?.data });
            history.push("/dashboard");
        }).catch(err => {
            setError(true)
        })
    }

    return (
        <div className="login-page">
            <div className="form">
                <h1 style={{ textAlign: "center" }}>Welcome</h1>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!',
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    {loginError && <span className="red">Invalid username or Password</span>}
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Submit
                    </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default AdLogin;
import React, { useState, useEffect } from "react";
import { Button, Input, Form, Select, Space } from "antd";
import axios from "axios";
import config from "../../config";
import { useSelector } from "react-redux";

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 12 },
};

function AddSubSubCategories({ subMenu }) {
  const [form] = Form.useForm();
  const [subSubMenu, setSubSubMenu] = useState();
  const {token} = useSelector((state) => state.authReducer);
  const handleSelect = (value) => {
    form.setFieldsValue({
      parentId: "",
    });
    setSubSubMenu(subMenu.filter((item) => item._id === value));
  };
  const handelSubMenu = (value) => {
    delete value["parent"];
    console.log(value);
    axios
      .post(`${config.url}category/`, value, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        form.resetFields();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Form {...layout} name="subSubMenu" onFinish={handelSubMenu} form={form}>
        <Form.Item
          label="Category"
          name="parent"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select onChange={handleSelect}>
            {subMenu &&
              subMenu.length > 0 &&
              subMenu.map((category, index) => {
                return (
                  <Option value={category._id} key={index.toString()}>
                    {category.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          label="subCategory"
          name="parentId"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select>
            {subSubMenu &&
              subSubMenu[0]["subMenu"].length &&
              subSubMenu[0].subMenu.map((category, index) => {
                return (
                  <Option value={category._id} key={index.toString()}>
                    {category.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Please enter the sub sub categories" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" shape="round" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}
export default AddSubSubCategories;

import { combineReducers } from 'redux';
import common from './commonReducer';
import navBarReducer from './navBarReducer';
import authReducer from './authReducer';

const rootReducer = combineReducers({
	common,
	navBarReducer,
	authReducer,
});
export default rootReducer;

import React, { useState, useEffect } from "react";
import { Button, Input, Form, Select, Space } from "antd";
import axios from "axios";
import config from "../../config";
import { useSelector } from "react-redux";

const { Option } = Select;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 12 },
};

function AddSubCategories({ categories, setCategories }) {
  const [form] = Form.useForm();
  const {token} = useSelector((state) => state.authReducer);
  const handelSubMenu = (value) => {
    axios
      .post(`${config.url}category/`, value, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        form.resetFields();

        axios
          .get(`${config.url}category/`)
          .then((response) => {
            setCategories(response.data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Form
        {...layout}
        name="subMenu"
        onFinish={(value) => handelSubMenu(value)}
        form={form}
      >
        <Form.Item
          label="Category"
          name="parentId"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select>
            {categories.map((category, index) => {
              return (
                <Option value={category._id} key={index.toString()}>
                  {category.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Please enter sub categories name" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Space>
            <Button type="primary" shape="round" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}
export default AddSubCategories;

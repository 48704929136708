import React, { useState, useEffect } from "react";
import { Tabs, Table, Button, Input, Form, Select, Space, Radio } from "antd";
import axios from "axios";
import config from "../config";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "antd/lib/modal/Modal";
import "./styles/category.css";
import AddCategories from "./categories/AddCategories";
import AddSubCategories from "./categories/AddSubCategories";
import AddSubSubCategories from "./categories/AddSubSubCategories";

const { TabPane } = Tabs;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 12 },
};

function Categories() {
  const {token} = useSelector((state) => state.authReducer);
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [editId, setEditId] = useState("");
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [radioModel, setRadioModel] = useState(false);
  const [value, setValue] = useState(null);
  const dispatch = useDispatch();
  const [subMenuTitle, setSubMenuTitle] = useState([]);
  const [titleModel, setTitleModel] = useState(false);
  const [subtitle, setTitle] = useState({ oldTitle: "", title: "" });
  const [subMenu, setSubMenu] = useState();

  const [form] = Form.useForm();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            shape="round"
            onClick={() => editCategory(record)}
          >
            Edit
          </Button>
          <Button
            type="primary"
            danger
            shape="round"
            onClick={() => showDeleteModel(record._id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const SubMenuColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            shape="round"
            onClick={() => editTitle(record.title)}
          >
            Edit
          </Button>
          {/* <Button
            type="primary"
            danger
            shape="round"
            onClick={() => showDeleteModel(record._id)}
          >
            Delete
          </Button> */}
        </Space>
      ),
    },
  ];

  const editCategory = (category) => {
    setModalShow(true);
    setEditId(category._id);
    form.setFieldsValue({
      editName: category.name,
    });
    setCategoryName(category.name);
  };
  const editTitle = (title) => {
    setTitleModel(true);
    form.setFieldsValue({
      title,
    });
    setTitle({ ...subtitle, oldTitle: title });
  };

  const handleEditCategory = () => {
    axios
      .put(
        `${config.url}category/`,
        { id: editId, name: categoryName },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        categories.map((item) => {
          if (item._id === res.data._id) {
            item.name = categoryName;
          }
        });
        form.resetFields();
        setCategories(categories);
        setModalShow(false);
      })
      .catch((err) => {
        if (err.request.status === 401) {
          dispatch({ type: "logout" });
          history.push("/administrator");
        }
      });
  };
  const showDeleteModel = (id) => {
    setDeleteId(id);
    setRadioModel(true);
  };
  const deleteCategory = () => {
    if (value === "1") {
      axios
        .delete(`${config.url}category/`, {
          headers: { Authorization: `Bearer ${token}` },
          data: { id: deleteId },
        })
        .then((res) => {
          let cats = categories.filter((cat) => cat._id !== deleteId);
          setCategories(cats);
          setRadioModel(false);
        })
        .catch((err) => {
          if (err.request.status === 401) {
            setRadioModel(false);
            dispatch({ type: "logout" });
            history.push("/administrator");
          }
        });
    } else {
      setRadioModel(false);
    }
  };

  useEffect(() => {
    axios
      .get(`${config.url}category/`)
      .then((response) => {
        setCategories(response.data);
      })
      .catch((err) => setCategories([]));
    axios
      .get(`${config.url}category/menu`)
      .then((response) => {
        setSubMenu(response.data);
      })
      .catch((err) => setCategories([]));
  }, []);

  const handleEditTitle = () => {
    axios
      .put(`${config.url}questions/update-title`, subtitle, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        subMenuTitle.map((item) => {
          if (item.title === subtitle.oldTitle) {
            item.title = subtitle.title;
          }
        });
        form.resetFields();
        setSubMenuTitle(subMenuTitle);
        setTitleModel(false);
      })
      .catch((err) => {
        if (err.request.status === 401) {
          dispatch({ type: "logout" });
          history.push("/administrator");
        }
      });
  };

  return (
    <>
      <Tabs defaultActiveKey="2">
        <TabPane tab="Add a Category" key="1">
          <AddCategories setCategories={(value) => setCategories(value)} />
        </TabPane>
        <TabPane tab="View Categories" key="2">
          <Table
            columns={columns}
            dataSource={categories}
            rowKey="_id"
            pagination={{ pageSize: 5 }}
          />
        </TabPane>

        <TabPane tab="Sub Menu " key="4">
          <AddSubCategories
            categories={categories}
            setCategories={(value) => setCategories(value)}
          />
        </TabPane>
        <TabPane tab="Sub Sub Menu " key="5">
          <AddSubSubCategories subMenu={subMenu} />
        </TabPane>
      </Tabs>
      <Modal
        title="Edit Category"
        visible={modalShow}
        centered
        maskClosable={false}
        onCancel={() => setModalShow(false)}
        footer={null}
      >
        <Form
          {...layout}
          name="basicEdit"
          // onFinish={handleEditCategory}
          form={form}
        >
          <Form.Item
            label="Category"
            name="editName"
            rules={[{ required: true, message: "Please input Category name" }]}
          >
            <Input onChange={(e) => setCategoryName(e.target.value)} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" shape="round" onClick={handleEditCategory}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* title update model */}
      <Modal
        title="Edit Title"
        visible={titleModel}
        centered
        maskClosable={false}
        onCancel={() => setTitleModel(false)}
        footer={null}
      >
        <Form
          {...layout}
          name="basicEdit"
          // onFinish={handleEditCategory}
          form={form}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please input Category name" }]}
          >
            <Input
              onChange={(e) => setTitle({ ...subtitle, title: e.target.value })}
            />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" shape="round" onClick={handleEditTitle}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* end title model */}

      <Modal
        title="Delete Category"
        visible={radioModel}
        centered
        maskClosable={false}
        onCancel={() => setRadioModel(false)}
        footer={null}
      >
        <>
          <p className="d-Title">
            Are you sure you want to delete this category?
          </p>
          <Radio.Group value={value} onChange={(e) => setValue(e.target.value)}>
            <Space size="large" className="radio-left" direction="horizontal">
              <Radio value={"1"}>Yes</Radio>
              <Radio value={"2"}>No</Radio>
            </Space>
          </Radio.Group>

          <Button
            className="delete-submit"
            key="submit"
            shape="round"
            type="primary"
            onClick={deleteCategory}
          >
            Submit
          </Button>
        </>
      </Modal>
    </>
  );
}

export default Categories;

import React from "react";
import "./styles/about.css";
function Essay({ essay }) {
  return (
    <>
      <div class="row1">
        <div class="container">
          <div dangerouslySetInnerHTML={{ __html: essay }} />
          {/* <p class="title">{essay}</p> */}
        </div>
      </div>
    </>
  );
}
export default Essay;

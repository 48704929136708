import React, { useEffect, useState } from "react";
import { Tabs, Menu } from "antd";
import QuestionList from "./QuestionList";
import axios from "axios";
import config from "../config";
import AddQuestions from "./AddQuestions";
import "./styles/question.css";
import AddEssay from "./AddEssay";
const { SubMenu } = Menu;
const { TabPane } = Tabs;

function Questions() {
  const [category, setCategory] = useState({});
  const [categories, setCategories] = useState([{ _id: "all", name: "All" }]);
  const [sunMenu, setSubMenu] = useState([]);
  useEffect(() => {
    axios.get(`${config.url}category/menu`).then((res) => {
      setCategories(res.data);
      setCategory(categories[0]);
      axios.get(`${config.url}questions/title`).then((res) => {
        setSubMenu(res.data);
      });
    });
  }, []);
  const handelItem = (item) => {
    setCategory({ _id: item.key });
    console.log("item", item);
  };
  return (
    <>
      <Tabs defaultActiveKey="2">
        <TabPane tab="Add a question" key="1">
          <AddQuestions />
        </TabPane>
        <TabPane tab="View questions" key="2">
          <div className="d-flex">
            <div className="scroll">
              <Menu
                defaultSelectedKeys={"all"}
                key="menu"
                mode="inline"
                onClick={handelItem}
              >
                {categories.map((cat, index) => {
                  return (
                    <>
                      {cat.subMenu && cat["subMenu"].length === 0 && (
                        <Menu.Item key={cat._id}>{cat.name}</Menu.Item>
                      )}
                      {cat.subMenu &&
                        cat["subMenu"].length !== 0 &&
                        cat._id === "5fff0b1d9e31a5e9f23ab8df" && (
                          <SubMenu
                            className="sub-size"
                            key="sub1"
                            title={cat.name}
                          >
                            {cat.subMenu.map((item) => (
                              <Menu.Item key={item._id}>{item.name}</Menu.Item>
                            ))}
                          </SubMenu>
                        )}
                      {cat.subMenu &&
                        cat["subMenu"].length !== 0 &&
                        cat._id === "6135a866268ea400161479bf" && (
                          <SubMenu
                            className="sub-size"
                            key="sub2"
                            title={cat.name}
                          >
                            {cat.subMenu.map((item) => (
                              <Menu.Item key={item._id}>{item.name}</Menu.Item>
                            ))}
                          </SubMenu>
                        )}
                      {cat.subMenu &&
                        cat["subMenu"].length !== 0 &&
                        cat._id === "6135a86f268ea400161479c2" && (
                          <SubMenu
                            className="sub-size"
                            key="sub3"
                            title={cat.name}
                          >
                            {cat.subMenu.map((item) => (
                              <Menu.Item key={item._id}>{item.name}</Menu.Item>
                            ))}
                          </SubMenu>
                        )}
                    </>
                  );
                })}
              </Menu>
              {/* <ul>
                                {
                                    categories.map((cat, index) => {
                                        return (<li style={{ listStyle: "none", padding: "5px", cursor: "pointer" }} onClick={() => setCategory(cat)} className={category._id === cat._id ? "active" : ""} key={index.toString()}>{cat.name}</li>)
                                    })
                                }
                            </ul> */}
            </div>
            <div
              id="content-questions"
              // className="content-questions"
              style={{ width: "75%", height: "74vh", overflow: "auto" }}
            >
              <QuestionList category={category} />
            </div>
          </div>
        </TabPane>
        <TabPane tab="Add Essays" key="3">
          <AddEssay />
        </TabPane>
      </Tabs>
    </>
  );
}

export default Questions;
